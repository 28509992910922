import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { fetchAllAnalysisFilesSummary, fetchFileSummary } from "./thunks";
import { FileSummary, UpdatedFileDemographicParams } from "ts/file";
import { DateFormat, DemographicTypes, FileUploadStatus, ImportMode } from "@explorance/mly-types";

type UpdateDemographicFieldPayload = {
  demographicId: number;
  name?: string;
  selectedType?: DemographicTypes;
  dateFormat?: DateFormat;
};

type DataSourceState = {
  analysisFileSummary: FileSummary;
  suggestedUniqueColumns: string[];
  uploadStatus: FileUploadStatus;
  isProcessingFinalStep: boolean;
  // importModal
  importModalOpen: boolean;
  importModalFileId: number;
  importModalAnalysisId: number;
  // inputs
  selectedImportType: ImportMode;
  selectedUniqueColumn: string;
  updatedDemographicData: UpdatedFileDemographicParams[];
};

const initialState: DataSourceState = {
  analysisFileSummary: null,
  suggestedUniqueColumns: null,
  uploadStatus: null,
  isProcessingFinalStep: false,
  importModalOpen: false,
  importModalFileId: null,
  importModalAnalysisId: null,
  selectedImportType: null,
  selectedUniqueColumn: null,
  updatedDemographicData: null,
};

const dataSourceSlice = createSlice({
  name: "dataSource",
  initialState,
  reducers: {
    setAnalysisFileSummary: (state, action: PayloadAction<FileSummary>) => {
      state.analysisFileSummary = action.payload;
    },

    setSelectedImportType: (state, action: PayloadAction<ImportMode>) => {
      state.selectedImportType = action.payload;
    },
    setSelectedUniqueColumn: (state, action: PayloadAction<string>) => {
      state.selectedUniqueColumn = action.payload;
    },
    setIsProcessingFinalStep: (state, action: PayloadAction<boolean>) => {
      state.isProcessingFinalStep = action.payload;
    },
    setImportModalOpen: (state, action: PayloadAction<boolean>) => {
      state.importModalOpen = action.payload;
    },
    setImportModalFileId: (state, action: PayloadAction<number>) => {
      state.importModalFileId = action.payload;
      state.importModalAnalysisId = null;
    },
    setImportModalAnalysisId: (state, action: PayloadAction<number>) => {
      state.importModalAnalysisId = action.payload;
      state.importModalFileId = null;
    },
    updateDemographicField: (state, action: PayloadAction<UpdateDemographicFieldPayload>) => {
      const { demographicId, ...updatedDemographicProperties } = action.payload;
      const demographicIndex = state.updatedDemographicData.findIndex(
        (d) => d.id === demographicId
      );

      if (demographicIndex !== -1) {
        const newDemographic = cloneDeep({
          ...state.updatedDemographicData[demographicIndex],
          ...updatedDemographicProperties,
        });
        if (newDemographic.selectedType !== DemographicTypes.Date && newDemographic.dateFormat) {
          delete newDemographic.dateFormat;
        }
        state.updatedDemographicData[demographicIndex] = newDemographic;
      }
    },

    resetDataSource: (state) => {
      state.analysisFileSummary = null;
      state.suggestedUniqueColumns = null;
      state.uploadStatus = null;
      // inputs
      state.selectedImportType = initialState.selectedImportType;
      state.selectedUniqueColumn = null;
    },
  },
  extraReducers: (builder) => {
    // GET files/:id case
    builder.addCase(fetchFileSummary.pending, (state) => {
      state.selectedImportType = ImportMode.AppendAndUpdate;
    });
    builder.addCase(fetchFileSummary.fulfilled, (state, action) => {
      state.uploadStatus = action.payload.uploadStatus;
      if (action.payload.uploadStatus === FileUploadStatus.Uploading) return;

      state.analysisFileSummary = {
        demographics: action.payload.demographics,
        comments: action.payload.comments,
        rowCount: action.payload.rowCount,
      };
      state.suggestedUniqueColumns = action.payload.suggestedUniqueColumns;
      state.selectedUniqueColumn = action.payload.suggestedUniqueColumns?.[0];
      // inputs
      state.updatedDemographicData = action.payload.demographics?.map((d) => {
        return {
          id: d.id,
          selectedType: d.selectedType || d.suggestedTypes[0],
          dateFormat: d.dateFormat,
          name: d.name,
        };
      });
    });
    // GET analysis/:id/files case
    builder.addCase(fetchAllAnalysisFilesSummary.pending, (state) => {
      state.selectedImportType = null;
    });
    builder.addCase(fetchAllAnalysisFilesSummary.fulfilled, (state, action) => {
      state.uploadStatus = FileUploadStatus.Completed;
      state.analysisFileSummary = {
        demographics: action.payload.demographics,
        comments: action.payload.comments,
        rowCount: action.payload.rowCount,
      };
      state.suggestedUniqueColumns = null;
      state.selectedUniqueColumn = null;
      state.selectedImportType = null;
      state.updatedDemographicData = action.payload.demographics?.map((d) => {
        return {
          id: d.id,
          selectedType: d.selectedType || d.suggestedTypes[0],
          dateFormat: d.dateFormat,
          name: d.name,
        };
      });
    });
  },
});

export const {
  setAnalysisFileSummary,
  resetDataSource,
  setIsProcessingFinalStep,
  setSelectedImportType,
  setSelectedUniqueColumn,
  updateDemographicField,
  setImportModalOpen,
  setImportModalFileId,
  setImportModalAnalysisId,
} = dataSourceSlice.actions;

export default dataSourceSlice.reducer;
