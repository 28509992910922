import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";

import { Modal } from "components/_modals/Modal";
import { ImportSettingsStep } from "./ImportSettingsStep";
import { UploadFileStep } from "./UploadFileStep";
import { Button } from "components/_buttons/Button";
import { Text } from "components/Text";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { FileUploadStatus } from "@explorance/mly-types";
import { Color } from "ts/enums/color";
import { fetchAllAnalysisFilesSummary, fetchFileSummary } from "store/analysisSettings/thunks";
import {
  resetDataSource,
  setIsProcessingFinalStep,
  setImportModalOpen,
} from "store/analysisSettings/dataSourceSlice";
import { DataSourceModalType } from "ts/enums/dataSourceModalType";
import { saveFileSettings, updateAnalysisFileSettings } from "services/files";
import { UploadFailedScreen } from "./UploadFailedScreen";
import { voidFunc } from "utils/voidFunc";

const modalStyles = {
  content: {
    margin: "auto",
    boxShadow: "0px 4px 16px #B6BACB29",
    width: "fit-content",
    height: "fit-content",
    border: `1px solid ${Color.neutral30}`,
    borderRadius: "5px",
    backgroundColor: Color.white,
    transition: "height .5s ease-in-out",
    overflow: "hidden",
  },
};

type Props = {
  fileUploadStatus: FileUploadStatus;
  type: DataSourceModalType;
  importSuccessCallback?: () => void;
};

export const DataSourceModal = ({ fileUploadStatus, type, importSuccessCallback }: Props) => {
  const state = useAppSelector((state) => state.dataSource);
  const dataSourceState = useAppSelector((state) => state.dataSource);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const fileImportFailed = [FileUploadStatus.Failed, FileUploadStatus.ImportUploadError].includes(
    fileUploadStatus
  );

  useEffect(() => {
    if (!state.importModalOpen) return;
    if (type === DataSourceModalType.ImportFile && state.importModalFileId) {
      dispatch(fetchFileSummary(state.importModalFileId));
    }
    if (type === DataSourceModalType.ViewData && state.importModalAnalysisId) {
      dispatch(fetchAllAnalysisFilesSummary(state.importModalAnalysisId));
    }
  }, [
    dispatch,
    type,
    state.importModalAnalysisId,
    state.importModalFileId,
    state.importModalOpen,
    fileUploadStatus,
  ]);

  const handleCloseModal = () => {
    dispatch(setImportModalOpen(false));
    dispatch(resetDataSource());
  };

  const step = [
    FileUploadStatus.Uploading,
    FileUploadStatus.Failed,
    FileUploadStatus.ImportUploadError,
  ].includes(fileUploadStatus)
    ? 1
    : 2;

  const handleSave = async () => {
    if (step === 1) {
      dispatch(setImportModalOpen(true));
      return;
    }

    if (step === 2) {
      dispatch(setIsProcessingFinalStep(true));
      if (type === DataSourceModalType.ImportFile) {
        await saveFileSettings(state.importModalFileId, {
          demographics: dataSourceState.updatedDemographicData,
          selectedUniqueColumn: dataSourceState.selectedUniqueColumn || null,
          importMode: dataSourceState.selectedImportType,
        });
      } else if (type === DataSourceModalType.ViewData) {
        await updateAnalysisFileSettings(
          state.importModalAnalysisId,
          dataSourceState.updatedDemographicData
        );
      }
    }
  };

  // Execute behavior after the import process is completed
  useEffect(() => {
    if (
      importSuccessCallback &&
      state.importModalOpen &&
      dataSourceState.isProcessingFinalStep &&
      fileUploadStatus === FileUploadStatus.Completed
    ) {
      dispatch(setIsProcessingFinalStep(false));
      dispatch(setImportModalOpen(false));
      importSuccessCallback();
    }
  }, [
    dispatch,
    fileUploadStatus,
    history,
    dataSourceState.isProcessingFinalStep,
    state.importModalOpen,
    type,
    importSuccessCallback,
  ]);

  const renderMainContent = () => {
    switch (step) {
      case 1:
        return fileImportFailed ? <UploadFailedScreen /> : <UploadFileStep />;
      case 2:
        return (
          <ImportSettingsStep
            type={type}
            isDemographicSelectionDisabled={dataSourceState.isProcessingFinalStep}
            fileUploadStatus={fileUploadStatus}
          />
        );
      default:
        return null;
    }
  };

  const isSavingDisabled =
    step === 1 ||
    dataSourceState.isProcessingFinalStep ||
    (type === DataSourceModalType.ImportFile &&
      ![FileUploadStatus.Completed, FileUploadStatus.WaitingForInput].includes(fileUploadStatus)) ||
    (type === DataSourceModalType.ViewData && fileUploadStatus !== FileUploadStatus.Completed);

  const saveCTAResourceKey = {
    [DataSourceModalType.ImportFile]: "button.next",
    [DataSourceModalType.ViewData]: "button.save",
  }[type];

  return (
    <Modal isOpen={state.importModalOpen} styles={modalStyles}>
      <StyledImportProgressModalContent importFailed={fileImportFailed}>
        {renderMainContent()}
        <StyledActionsSection>
          <Button variant={ButtonVariant.outline} size={ButtonSize.md} onClick={handleCloseModal}>
            <Text resource="button.close" />
          </Button>
          {step === 2 && !fileImportFailed && (
            <Button
              variant={ButtonVariant.primary}
              size={ButtonSize.md}
              disabled={isSavingDisabled}
              loading={dataSourceState.isProcessingFinalStep}
              onClick={handleSave}
            >
              <Text resource={saveCTAResourceKey} />
            </Button>
          )}
          {fileImportFailed && (
            <Button variant={ButtonVariant.primary} size={ButtonSize.md} onClick={voidFunc}>
              <Text resource="modal.import.failed.reImport" />
            </Button>
          )}
        </StyledActionsSection>
      </StyledImportProgressModalContent>
    </Modal>
  );
};

const StyledImportProgressModalContent = styled.div<{ importFailed: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ importFailed }) => (importFailed ? "738px" : "1170px")};
  height: ${({ importFailed }) => (importFailed ? "468px" : "680px")};
  ${({ importFailed }) =>
    !importFailed &&
    `
  @media (max-width: 1366px) {
    width: 950px;
    height: 680px;
  }
  `}
`;

const StyledActionsSection = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
  justify-content: flex-end;
  margin-top: auto;
  padding-top: 24px;
`;
