import { mlyApi, getHeaders } from "config/mlyApi";
import { FeedbackType, InsightType } from "@explorance/mly-types";

type SendCommentFeedbackParams = {
  commentId: number;
  topics: string[];
  feedbackType: FeedbackType;
  insight: InsightType;
  feedback?: string;
};

export async function sendFeedback({
  commentId,
  topics,
  feedbackType,
  insight,
  feedback,
}: SendCommentFeedbackParams) {
  const requestConfig = await getHeaders();
  const requestBody = {
    feedback,
    feedbackType,
    topics,
    insight,
  };
  const url = `/comments/${commentId}/feedbacks`;
  return mlyApi.post<any>(url, requestBody, requestConfig);
}

export async function getDiscussionThreadNotes(commentId: number) {
  const requestConfig = await getHeaders();
  const url = `/comments/${commentId}/notes`;
  return mlyApi.get<any>(url, requestConfig);
}

export async function createDiscussionThreadNote(commentId: number, note: string) {
  const requestConfig = await getHeaders();
  const requestBody = { note };
  const url = `/comments/${commentId}/notes`;
  return mlyApi.post<any>(url, requestBody, requestConfig);
}
