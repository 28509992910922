import React from "react";
import styled from "styled-components";

import { Icon, IconType } from "components/_icons/Icon";
import { Select } from "components/_inputs/Select";
import { Text } from "components/Text";
import { useAppDispatch, useAppSelector } from "store";
import { updateDemographicField } from "store/analysisSettings/dataSourceSlice";
import { DateFormat, DemographicTypes, ImportMode } from "@explorance/mly-types";
import { Color } from "ts/enums/color";
import { FileDemographic } from "ts/file";
import { SelectOption } from "ts/select";

type Props = {
  demographic: FileDemographic;
  isDemographicSelectionDisabled: boolean;
};

export const DemographicHeaderCell = ({ demographic, isDemographicSelectionDisabled }: Props) => {
  const { updatedDemographicData, selectedUniqueColumn, selectedImportType } = useAppSelector(
    (state) => state.dataSource
  );
  const dispatch = useAppDispatch();

  const getDatatypeDropdownOptions = (suggestedTypes: DemographicTypes[]) =>
    suggestedTypes.map((st: DemographicTypes) => ({
      label: <Text resource={`dataSource.dropdown.suggestedType.[${st}]`} />,
      value: st,
      ...(st === DemographicTypes.Date && {
        childOptions: Object.values(DateFormat).map((df) => ({
          label: df,
          value: df,
        })),
      }),
    }));

  const displaySelectedUniqueColumnStyle = (demographic: FileDemographic) =>
    selectedImportType !== ImportMode.ImportAlways && selectedUniqueColumn === demographic.name;

  const getSelectedDatatypeOption = (d, datatypeDropdownOptions: SelectOption[]) => {
    const selectedDemographic = updatedDemographicData.find((data) => data.id === d.id);
    if (!selectedDemographic) return;

    if (
      selectedDemographic.selectedType === DemographicTypes.Date &&
      selectedDemographic.dateFormat
    ) {
      const parentOption = datatypeDropdownOptions.find(
        (option) => option.value === DemographicTypes.Date
      );
      const childOption = parentOption?.childOptions?.find(
        (child) => child.value === selectedDemographic.dateFormat
      );
      return childOption;
    }

    return datatypeDropdownOptions.find(
      (option) => option.value === selectedDemographic.selectedType
    );
  };

  const handleChangeDatatype = (
    d: FileDemographic,
    selectedOption: DemographicTypes | DateFormat
  ) => {
    const isDate = Object.values(DateFormat).includes(selectedOption as DateFormat);
    const selectedType = isDate ? DemographicTypes.Date : (selectedOption as DemographicTypes);
    const dateFormat = isDate ? (selectedOption as DateFormat) : null;

    dispatch(
      updateDemographicField({
        demographicId: d.id,
        selectedType,
        ...(dateFormat && { dateFormat }),
      })
    );
  };

  const datatypeDropdownOptions = getDatatypeDropdownOptions(demographic.suggestedTypes);
  const selectedDatatypeOption = getSelectedDatatypeOption(demographic, datatypeDropdownOptions);
  const isDateSelected =
    updatedDemographicData.find((data) => data.id === demographic.id)?.selectedType ===
    DemographicTypes.Date;

  const SelectedDateText = (
    <Text
      resource={{
        key: "dataSource.dropdown.dateFormat",
        args: [selectedDatatypeOption?.label as string],
      }}
    />
  );

  return (
    <StyledTh key={demographic.id} isSelected={displaySelectedUniqueColumnStyle(demographic)}>
      <StyledDemographicNameCell>
        <StyledDemographicName>{demographic.name}</StyledDemographicName>
        {displaySelectedUniqueColumnStyle(demographic) && (
          <StyledSelectedUniqueColumnIconContainer>
            <Icon type={IconType.checkCircle} color={Color.white} size={16} />
          </StyledSelectedUniqueColumnIconContainer>
        )}
      </StyledDemographicNameCell>
      {updatedDemographicData?.length > 0 && (
        <StyledDataTypeSelectionCell>
          <Select
            buttonStyle={{ width: isDateSelected ? "180px" : "100%" }}
            selectStyle={{ width: "min(100%, 180px)" }}
            dropdownWidth="clamp(112px, 100%, 180px)"
            options={datatypeDropdownOptions}
            selectedOption={selectedDatatypeOption}
            handleChange={(parentOption) => handleChangeDatatype(demographic, parentOption)}
            dropdownPosition={{ top: 35 }}
            disabled={isDemographicSelectionDisabled}
            {...(isDateSelected && { buttonLabelOverride: SelectedDateText })}
          />
        </StyledDataTypeSelectionCell>
      )}
    </StyledTh>
  );
};

const StyledTh = styled.th<{ isSelected: boolean }>`
  box-sizing: content-box;
  font-weight: bold;
  text-align: left;

  border-top: 1px solid ${Color.blue20};
  border-bottom: 1px solid ${Color.blue20};
  min-width: 95px;
  max-width: 450px;
  vertical-align: top;
  background-color: ${(props) => (props.isSelected ? Color.gray40 : Color.sky20)};
  color: ${(props) => (props.isSelected ? Color.white : Color.gray50)};
`;

const StyledDemographicNameCell = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledDemographicName = styled.div`
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 18px;
`;

const StyledSelectedUniqueColumnIconContainer = styled.div`
  float: right;
  margin-right: 16px;
`;

const StyledDataTypeSelectionCell = styled.div`
  border-top: 1px solid ${Color.blue20};
  padding: 10px 16px;
`;
