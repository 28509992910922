import React, { useState } from "react";
import styled from "styled-components";

import useClickOutside from "hooks/useClickOutside";

import { AnalysisSettings } from "pages/analysis/[id]/overview/_layouts/blocks/AnalysisSettings";
import { Text } from "components/Text";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";

export const AnalysisSettingsButton = () => {
  const [showAnalysisSettingsMenu, setShowAnalysisSettingsMenu] = useState<boolean>(false);
  const analysisSettingsButtonRef = useClickOutside(() => setShowAnalysisSettingsMenu(false));

  return (
    <StyledAnalysisSettingsButtonContainer ref={analysisSettingsButtonRef}>
      <Button
        variant={ButtonVariant.outlineBlue}
        size={ButtonSize.sm}
        onClick={() => setShowAnalysisSettingsMenu((show) => !show)}
      >
        <Text resource="button.analysisProperties" />
        <Icon type={IconType.chevronDown} size={8} style={{ marginLeft: 8 }} />
      </Button>

      <StyledAnalysisSettingsMenu show={showAnalysisSettingsMenu}>
        <AnalysisSettings />
        <Button
          variant={ButtonVariant.light}
          size={ButtonSize.ml}
          style={{ display: "block", marginLeft: "auto", marginTop: 16 }}
          onClick={() => setShowAnalysisSettingsMenu(false)}
        >
          <Text resource="button.close" />
        </Button>
      </StyledAnalysisSettingsMenu>
    </StyledAnalysisSettingsButtonContainer>
  );
};

const StyledAnalysisSettingsButtonContainer = styled.div`
  position: relative;
`;

const StyledAnalysisSettingsMenu = styled.div<{ show: boolean }>`
  transition: 0.2s opacity, 0.2s visibility;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  opacity: ${({ show }) => (show ? 1 : 0)};
  background-color: ${Color.white};
  position: absolute;
  border-radius: 10px;
  padding: 16px;
  box-shadow: 0 3px 10px rgba(53, 56, 77, 0.2);
  right: 0;
  top: calc(100% + 6px);
  width: 532px;
  display: block;
`;
