import { cloneDeep } from "lodash";
import { AnalysisDetails } from "ts/analysis";

export const formatAnalysisModels = async (modelsApiData, topicsApiData, getModelName) => {
  let topicsCopy = cloneDeep(topicsApiData);
  const baseModel = topicsCopy.find((m) => !m.isVirtual);
  const sm = Object.keys(modelsApiData.models)
    .map((modelKey) =>
      modelsApiData.models[modelKey].map((model) => ({
        ...model,
        categorizationType: modelKey,
      }))
    )
    .flat()
    .filter((model) => model.graphId === baseModel?.modelId)[0];
  // filter the getTopicsByAnalysisId data copy to exclude the categorization model
  topicsCopy = topicsCopy.filter((mc) => mc.isVirtual);
  // construct the categorization model's name
  const modelName = getModelName(sm);
  // return the analysisModels state equal to all custom models and the categorization model
  return [
    { ...baseModel, modelName, env: sm?.env, categorizationType: sm?.categorizationType },
    ...topicsCopy,
  ];
};

export const formatAnalysisDetails = (analysisId, analysisDetailsApiData): AnalysisDetails => {
  return {
    id: analysisId,
    name: analysisDetailsApiData.name,
    graphId: analysisDetailsApiData.settings?.graphId,
    env: analysisDetailsApiData.settings?.env,
    precision: analysisDetailsApiData.settings?.precision,
    allColumns: analysisDetailsApiData.columns,
    selectedColumns: analysisDetailsApiData.selectedColumns,
    selectedCommentersColumn: analysisDetailsApiData.commenterColumn,
    selectedVariableMappingId: analysisDetailsApiData.variableMappingId,
    selectedVariableMappingName: analysisDetailsApiData.variableMappingName,
    maxSelectableColumns: analysisDetailsApiData.maxSelectableColumns,
    status: analysisDetailsApiData.status,
    uploadStatus: analysisDetailsApiData.uploadStatus,
    lastUploadedFileId: analysisDetailsApiData.lastUploadfileId,
    availableResults: analysisDetailsApiData.availableResults,
    sharing: analysisDetailsApiData.sharingProperties,
    selectedModel: analysisDetailsApiData.selectedModel,
    suggestedModel: analysisDetailsApiData.suggestedModel,
    suggestedColumns: analysisDetailsApiData.suggestedColumns,
    availableVariableMappings: analysisDetailsApiData.availableVariableMappings,
    excludedValues: analysisDetailsApiData.excludedValues,
    totalExcluded: analysisDetailsApiData.totalExcluded,
    translationLanguages: analysisDetailsApiData.settings.translationLanguages,
    availableLanguages: analysisDetailsApiData.settings.translationLanguages,
  };
};
