import React, { useState } from "react";
import styled from "styled-components";

import { startCase } from "lodash";

import { CollapsibleName } from "components/CollapsibleName";
import { SectionTitle } from "components/SectionTitle";

import { Color } from "ts/enums/color";
import { Text } from "components/Text";

type Props = {
  sectionDetails: Record<string, any>;
};

export const DemographicsSection = ({ sectionDetails }: Props) => {
  const [isSectionOpen, setIsSectionOpen] = useState<boolean>(true);

  const detailObject = Object.keys(sectionDetails);

  return (
    <div>
      {/* keep as div to make it a block */}
      <CollapsibleName
        name={<SectionTitle mt={10} color={Color.gray30} titleKey="filter.demographics.label" />}
        isExpanded={isSectionOpen}
        onClick={() => setIsSectionOpen((openSection) => !openSection)}
        customStyles={{
          arrowColor: Color.gray30,
          labelMargin: 6,
          marginBottom: 0,
        }}
      />
      {isSectionOpen &&
        (detailObject.length > 0 ? (
          detailObject.map((d, index) => (
            <StyledDemographic key={`${d}-${index}`}>
              <StyledDetailTitle>{startCase(d)}</StyledDetailTitle>
              <StyledDetailBody>{sectionDetails[d]}</StyledDetailBody>
            </StyledDemographic>
          ))
        ) : (
          <StyledEmptyState>
            <Text resource="comments.detailsPopup.noDemographics.label" />
          </StyledEmptyState>
        ))}
    </div>
  );
};

const StyledDetailTitle = styled.div`
  font-weight: bold;
  margin-bottom: 4px;
`;

const StyledDetailBody = styled.div`
  margin-bottom: 12px;
`;

const StyledDemographic = styled.div`
  font-size: 14px;
  color: ${Color.gray50};
  margin-left: 14px;
  display: flex;
  flex-direction: column;
`;

const StyledEmptyState = styled.div`
  font-size: 13px;
  margin: 0px 14px 8px 14px;
  color: ${Color.neutral50};
`;
