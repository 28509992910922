import { GetResourceFunction } from "./useResource";

export const useGetModelName = (getResource: GetResourceFunction) => {
  return (model): string => {
    if (!model) return getResource("categorizationType.baseModel.acronym");

    if (model.customModelId) return model.modelName;

    // OVERRIDE FOR features/import-process-one-step only
    const languagesString = getResource(`categorizationType.${model.categorizationType}.name`);
    // const languagesString =
    //   model.languages?.map((l) => getResource(`languageCode.${l}.userLanguageName`)).join(", ") ||
    //   "";

    const powersetString = model.isPowerset ? getResource("term.powerset") : "";

    if (model.languages && model.languages.length > 1) {
      return `${getResource("term.multilingual")} (${languagesString}) ${powersetString}`;
    }
    return `${languagesString} ${powersetString}`;
  };
};
