import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";
import { useAppSelector } from "store";
import { routes } from "routes";

import {
  CommentsView,
  PermissionLevel,
  RoleType,
  SharingCommentExplorerAccess,
  SharingTopicExplorerAccess,
  TopicsListView,
  WidgetType,
  WidgetWidth,
} from "@explorance/mly-types";
import { ApiTopicNode } from "ts/topic";
import { Widget as WidgetModel } from "ts/widget";
import { Color } from "ts/enums/color";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { DemographicFilter } from "ts/filters/demographicFilter";
import { WidgetStatisticsApiData } from "ts/widgetStatistics";

import { AnalysisContext } from "context/AnalysisContext";
import {
  setSelectedModelId,
  setAnalysisError,
  handleAxiosError,
  setSelectedDemographicFilters,
  setSelectedTopicNodes,
  setThreshold,
  setSelectedColumnFilters,
  setSelectedAlertRange,
} from "context/AnalysisContext/actions";

import { getWidgetStatisticsByWidgetId } from "services/widgets";
import { mapDemographicFiltersForFrontend } from "utils/filters";
import { findOneTopicWithFullPath } from "utils/topics";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { useQueryParams } from "hooks/useQueryParams";
import { isAnyAdmin } from "utils/isAnyAdmin";

import { WidgetSettingsDrawer } from "common-layouts/WidgetSettingsDrawer";
import { SideDrawer } from "components/SideDrawer";
import { EmptyState } from "components/EmptyState";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { TextTruncator } from "components/TextTruncator";
import { WidgetStatsLoadingPlaceholder } from "./WidgetStatsLoadingPlaceholder";
import { WidgetFilterPills } from "./WidgetFilterPills";
import { WidgetTypeIcon } from "./WidgetTypeIcon";
import { WidgetStat } from "./WidgetStat";
import { GeneralMostAlertsStat } from "./GeneralMostAlertsStat";

type Props = {
  widget: WidgetModel;
  groupId: number;
  updatedWidgetId: number;
  onDelete: () => void;
  onSave: (widgetId: number) => void;
};

export type HandleClickCommentsParams = {
  topic?: ApiTopicNode[];
  breakdownDemographics?: DemographicFilter[];
  view?: CommentsView | TopicsListView;
  stats?: string[];
};

export type HandleClickTopicsParams = {
  topic: ApiTopicNode[];
  breakdownDemographics?: DemographicFilter[];
  view?: string;
  resetThreshold?: boolean;
};

export const Widget = ({ widget, updatedWidgetId, groupId, onDelete, onSave }: Props) => {
  // redux / context state
  const { currentUser } = useAppSelector((state) => state.auth);
  const [state, dispatch] = useContext(AnalysisContext);

  // hooks
  const { push } = useHistory();
  const widgetTitleRef = useRef<HTMLDivElement>();
  const { sharingPreview, sharingId, expandedRowId, sharedWithPage, step } = useQueryParams();

  // local state
  const [statistics, setStatistics] = useState<WidgetStatisticsApiData>(null);
  const [openSettings, setOpenSettings] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  // props
  const { modelId, isVirtual, threshold, demographics } = widget.filters;

  // Sharing-Related Access Variables
  const isAdmin = isAnyAdmin(currentUser.roleType);
  const isViewer = currentUser.roleType === RoleType.Viewer;
  const isAnalysisOwner = !state.analysisDetails?.sharing;
  const hasContributionPermission =
    state?.analysisDetails?.sharing?.permissionLevel === PermissionLevel.Contribution && !isViewer;
  const hasCommentsSharingAccess =
    state.analysisDetails?.sharing?.commentExplorerAccess === SharingCommentExplorerAccess.Shared;
  const hasTopicsSharingAccess =
    state.analysisDetails?.sharing?.topicExplorerAccess === SharingTopicExplorerAccess.Shared;
  const unrestrictedView =
    (!sharingPreview && (isAdmin || isAnalysisOwner || hasContributionPermission)) ||
    (isViewer && isAnalysisOwner);
  const canAccessComments = sharingPreview
    ? state.previewUser.commentExplorerAccess === SharingCommentExplorerAccess.Shared
    : isAdmin || isAnalysisOwner || hasCommentsSharingAccess;
  const canAccessTopics = sharingPreview
    ? state.previewUser.topicExplorerAccess === SharingTopicExplorerAccess.Shared
    : isAdmin || isAnalysisOwner || hasTopicsSharingAccess;

  const widths = {
    [WidgetWidth.OneThird]: { smScreen: 448, lgScreen: 374 },
    [WidgetWidth.TwoThirds]: { smScreen: 920, lgScreen: 770 },
    [WidgetWidth.Full]: { smScreen: 920, lgScreen: 1170 },
  };

  const width = widths[widget.configuration?.size?.width || WidgetWidth.OneThird];

  const getWidgetStats = useCallback(async () => {
    try {
      dispatch(setAnalysisError(null));
      const response = await getWidgetStatisticsByWidgetId(
        widget.id,
        isVirtual ? modelId : null,
        state.previewUser.id,
        sharingId
      );
      if (!response?.data) return setStatistics({ summary: null });
      setStatistics(response.data);
    } catch (err) {
      dispatch(handleAxiosError(err as AxiosError<any>));
      setStatistics({ summary: { data: [], type: null } });
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, isVirtual, modelId, widget.id, state.previewUser.id, sharingId]); //eslint-disable-line

  const addWidgetFiltersToContext = (
    widgetStatTopics?: ApiTopicNode[],
    breakdownDemographics?: DemographicFilter[],
    resetThreshold?: boolean
  ) => {
    if (demographics || breakdownDemographics) {
      //Filter out all demographics by name that are in the breakdownDemographics array
      const filteredDemographics = demographics
        ? demographics.filter((d) => !breakdownDemographics?.some((bd) => bd.name === d.name))
        : [];

      const combinedDemographicFilters = [
        ...(filteredDemographics ? mapDemographicFiltersForFrontend(filteredDemographics) : []),
        ...(breakdownDemographics || []),
      ];

      dispatch(setSelectedDemographicFilters(combinedDemographicFilters));
    }
    if (isVirtual || state.selectedModelId !== modelId) {
      dispatch(setSelectedModelId(modelId));
    }
    if (widgetStatTopics) {
      const modelTopics = state.analysisModels.find((m) => m.modelId === modelId)?.topics;
      const topicFilters = [...widgetStatTopics];
      const selectedTopics = topicFilters.map((t) => findOneTopicWithFullPath(modelTopics, t));
      dispatch(setSelectedTopicNodes(selectedTopics));
    }
    if (widget.filters?.alertRange) {
      dispatch(setSelectedAlertRange(widget.filters.alertRange));
    }
    dispatch(setThreshold(resetThreshold ? null : threshold ?? null));
    dispatch(setSelectedColumnFilters(widget.filters.selectedColumns || []));
  };

  const navigateToTopicsPageWithFilters = ({
    topic,
    breakdownDemographics,
    view,
    resetThreshold,
  }: HandleClickTopicsParams) => {
    addWidgetFiltersToContext(topic, breakdownDemographics, resetThreshold);
    push(
      routes.topicsPage(
        state.analysisDetails.id,
        getUrlQueryString({
          expandTree: true,
          sharingPreview,
          view,
          expandedRowId,
          sharedWithPage,
          sharingId,
          step,
        })
      )
    );
  };

  const navigateToCommentsPageWithFilters = (
    { topic, breakdownDemographics, view, stats }: HandleClickCommentsParams,
    resetThreshold?: boolean
  ) => {
    addWidgetFiltersToContext(topic, breakdownDemographics, resetThreshold);
    push(
      routes.commentsPage(state.analysisDetails.id) +
        getUrlQueryString({
          view,
          statistics: stats,
          sharingPreview,
          expandedRowId,
          sharedWithPage,
          sharingId,
          step,
        })
    );
  };

  useEffect(() => {
    getWidgetStats();
  }, [state.previewUser.id]); //eslint-disable-line

  // if the widget or its filters are updated, re-run widget stats request
  useEffect(() => {
    if (!updatedWidgetId) return;
    if (updatedWidgetId === widget.id) {
      getWidgetStats();
    }
  }, [widget.filters]); // eslint-disable-line

  const getWidgetContent = () => {
    // Mock Widget Statitics can be adjusted by changing L1 and L2 parameters

    const isGeneralMostAlertWidget = //set to true to display the general most alert widget
      widget.type === WidgetType.MostAlerts && !statistics.summary.data[0]?.value;

    if (statistics.summary.data.length === 0) {
      return (
        <EmptyState type={EmptyStateType.noFilterResults} customStyles={{ marginTop: "70px" }} />
      );
    }
    if (isGeneralMostAlertWidget) {
      return (
        <GeneralMostAlertsStat
          data={statistics.summary.data}
          onClickComments={canAccessComments && navigateToCommentsPageWithFilters}
          selectedStats={widget.configuration.stats}
        />
      );
    }
    return (
      <StyledStatsList>
        {statistics.summary.data.map((statistic, index) => (
          <WidgetStat
            key={index}
            data={statistic}
            l1Type={statistics.summary.type}
            isVirtual={isVirtual}
            onClickTopics={canAccessTopics ? navigateToTopicsPageWithFilters : undefined}
            onClickComments={canAccessComments ? navigateToCommentsPageWithFilters : undefined}
            widgetType={widget.type}
            selectedStats={widget.configuration.stats}
            widgetWidth={widget.configuration.size.width}
            widgetFilter={widget.filters}
            canAccessTopics={canAccessTopics}
            canAccessComments={canAccessComments}
          />
        ))}
      </StyledStatsList>
    );
  };

  return (
    <StyledWidget width={width}>
      <StyledWidgetHeader restrictedView={!unrestrictedView}>
        <WidgetTypeIcon className="type-icon" widgetType={widget.type} displayBackground={true} />
        <StyledWidgetTitle ref={widgetTitleRef}>
          <TextTruncator id={widget.id} value={widget.title} />
        </StyledWidgetTitle>
        {unrestrictedView && (
          <StyledSettingsButtonContainer>
            <Button
              variant={ButtonVariant.light}
              size={ButtonSize.sm}
              square
              onClick={() => setOpenSettings(true)}
            >
              <Icon type={IconType.gear} size={12} color={Color.gray20} />
            </Button>
          </StyledSettingsButtonContainer>
        )}
      </StyledWidgetHeader>
      {unrestrictedView && (
        <StyledWidgetFilterSection>
          <WidgetFilterPills filters={widget.filters} />
        </StyledWidgetFilterSection>
      )}
      {isLoading ? <WidgetStatsLoadingPlaceholder /> : getWidgetContent()}
      <SideDrawer
        render={WidgetSettingsDrawer}
        isOpen={openSettings}
        closeHandler={() => setOpenSettings(false)}
        width={"600px"}
        renderComponentProps={{
          isOpen: openSettings,
          selectedWidget: widget,
          groupId: groupId,
          onSave: onSave,
          onClose: () => setOpenSettings(false),
          onDelete: onDelete,
        }}
      />
    </StyledWidget>
  );
};

const StyledWidget = styled.div<{ width: { smScreen: number; lgScreen: number } }>`
  border: 1px solid blue;
  background: ${Color.white};
  box-shadow: 0px 3px 10px 0px ${Color.shadow20};
  border: 1px solid ${Color.sky20};
  border-radius: 10px;
  padding: 12px;
  height: 570px;
  display: flex;
  flex-flow: column;
  width: ${({ width }) => `${width.lgScreen}px`};

  @media (max-width: 1366px) {
    width: ${({ width }) => `${width.smScreen}px`};
  }
`;

const StyledWidgetFilterSection = styled.div`
  display: flex;
  margin: 6px 0px;
`;

const StyledSettingsButtonContainer = styled.span`
  margin-left: 10px;
  path {
    fill: ${Color.gray20} !important;
  }
  button {
    background-color: ${Color.sky20};
    &:hover {
      background-color: ${Color.sky15};
    }
  }
`;

const StyledWidgetHeader = styled.div<{ restrictedView: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 24px;
  margin-bottom: ${({ restrictedView }) => restrictedView && "15px"};
`;

const StyledWidgetTitle = styled.div`
  flex-grow: 1;
  height: 19px;
  font-size: 16px;
  font-weight: bold;
  max-lines: 2;
  width: calc(100% - 24px - 48px - 20px); // full width minus padding, icons, and gaps
`;

const StyledStatsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  overflow: overlay;
`;
