import React, { useState } from "react";

import { setComment } from "store/notes/discussionThreadSlice";
import { useAppDispatch } from "store";

import { Icon, IconType } from "components/_icons/Icon";
import { Button } from "components/_buttons/Button";
import { SideDrawer } from "components/SideDrawer";
import { DiscussionThreadDrawer } from "./DiscussionThreadDrawer";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { Comment } from "ts/comments";

type Props = {
  comment: Comment;
  disabled?: boolean;
};

export const DiscussionActionButton = ({ comment, disabled }: Props) => {
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => {
    setIsOpen(true);
    dispatch(setComment(comment));
  };

  return (
    <div>
      <Button
        variant={ButtonVariant.light}
        square
        disabled={disabled}
        tooltipContentKey={"comments.discussionThread.tooltip"}
        size={ButtonSize.sm}
        onClick={handleClick}
        style={{ color: Color.gray20 }}
      >
        <Icon type={IconType.commentBubble} color={Color.gray20} size={12} />
      </Button>
      <SideDrawer
        render={DiscussionThreadDrawer}
        isOpen={isOpen}
        closeHandler={() => setIsOpen(false)}
        renderComponentProps={{
          closeHandler: () => setIsOpen(false),
        }}
        width="462px"
      />
    </div>
  );
};
