import { InsightType } from "@explorance/mly-types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { sendFeedback } from "services/comments";
import { AppState } from "store/appReducer";

export const sendCommentFeedback = createAsyncThunk<void, void, { state: AppState }>(
  "feedback/sendFeedback",
  async (_, { getState }) => {
    const state = getState();
    const feedbackState = state.feedbackPopup;
    const hasTopics =
      [...feedbackState.selectedTopics, ...feedbackState.selectedRecommendations].length > 0;

    //send feedback if feedbackInputValue is not empty add it to function params
    await sendFeedback({
      commentId: feedbackState.comment.id,
      topics: [...feedbackState.selectedTopics, ...feedbackState.selectedRecommendations],
      feedbackType: feedbackState.analysisSentiment,
      insight:
        feedbackState.insightType == InsightType.TopicRecommendation && !hasTopics
          ? InsightType.Recommendation
          : feedbackState.insightType,
      ...(feedbackState.feedbackInputValue && { feedback: feedbackState.feedbackInputValue }),
    });
  }
);
