import { GetResourceFunction } from "./useResource";

export const useGetModelNameForModelDropdown = (getResource: GetResourceFunction) => {
  return (model): string => {
    if (!model) return getResource("categorizationType.baseModel.acronym");

    if (model.customModelId) return model.modelName;

    const modelNameString = getResource(`categorizationType.${model.categorizationType}.name`);
    const powersetString = model.isPowerset ? getResource("term.powerset") : "";

    if (model.languages && model.languages.length > 1) {
      return `${getResource("term.multilingual")} (${modelNameString}) ${powersetString}`;
    }
    return `${modelNameString} ${powersetString}`;
  };
};
