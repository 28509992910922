import React, { useState } from "react";
import styled from "styled-components";

import { Color } from "ts/enums/color";
import { ButtonSize, ButtonVariant } from "ts/enums/button";

import { StyledButton as StyledCommonButton } from "./StyledButton";
import { SquareButton } from "./SquareButton";

import { LoadingDots } from "components/LoadingDots";
import { useResource } from "hooks/useResource";
import { Tooltip } from "components/Tooltip";

const variantStyles = {
  backgroundColor: {
    [ButtonVariant.primary]: Color.blue50,
    [ButtonVariant.primaryPurple]: Color.indigo50,
    [ButtonVariant.secondary]: Color.sky15,
    [ButtonVariant.light]: Color.sky20,
    [ButtonVariant.outline]: Color.white,
    [ButtonVariant.danger]: Color.red50,
    [ButtonVariant.outlineBlue]: Color.white,
    [ButtonVariant.outlineGray]: Color.white,
    [ButtonVariant.error]: Color.red10,
    [ButtonVariant.neutral]: Color.white,
  },
  backgroundColorHover: {
    [ButtonVariant.primary]: Color.blue45,
    [ButtonVariant.primaryPurple]: Color.indigo45,
    [ButtonVariant.secondary]: Color.sky30,
    [ButtonVariant.light]: Color.sky10,
    [ButtonVariant.outline]: Color.sky15,
    [ButtonVariant.danger]: Color.red40,
    [ButtonVariant.outlineBlue]: Color.sky15,
    [ButtonVariant.outlineGray]: Color.sky15,
    [ButtonVariant.error]: Color.red10,
    [ButtonVariant.neutral]: Color.white,
  },
  backgroundColorActive: {
    [ButtonVariant.primary]: Color.blue55,
    [ButtonVariant.primaryPurple]: Color.indigo60,
    [ButtonVariant.secondary]: Color.sky40,
    [ButtonVariant.light]: Color.sky40,
    [ButtonVariant.outline]: Color.sky20,
    [ButtonVariant.danger]: Color.red55,
    [ButtonVariant.outlineBlue]: Color.sky20,
    [ButtonVariant.outlineGray]: Color.white,
    [ButtonVariant.error]: Color.white,
    [ButtonVariant.neutral]: Color.white,
  },
  backgroundColorDisabled: {
    [ButtonVariant.primary]: Color.neutral10,
    [ButtonVariant.primaryPurple]: Color.neutral10,
    [ButtonVariant.secondary]: Color.neutral10,
    [ButtonVariant.light]: Color.neutral10,
    [ButtonVariant.outline]: Color.white,
    [ButtonVariant.danger]: Color.neutral10,
    [ButtonVariant.outlineBlue]: Color.white,
    [ButtonVariant.outlineGray]: Color.neutral10,
    [ButtonVariant.error]: Color.neutral10,
    [ButtonVariant.neutral]: Color.neutral10,
  },
  color: {
    [ButtonVariant.primary]: Color.white,
    [ButtonVariant.primaryPurple]: Color.white,
    [ButtonVariant.secondary]: Color.blue50,
    [ButtonVariant.light]: Color.gray50,
    [ButtonVariant.outline]: Color.gray40,
    [ButtonVariant.danger]: Color.white,
    [ButtonVariant.outlineBlue]: Color.blue50,
    [ButtonVariant.outlineGray]: Color.gray50,
    [ButtonVariant.error]: Color.gray50,
    [ButtonVariant.neutral]: Color.gray30,
  },
  colorActive: {
    [ButtonVariant.primary]: Color.white,
    [ButtonVariant.primaryPurple]: Color.white,
    [ButtonVariant.secondary]: Color.blue55,
    [ButtonVariant.light]: Color.gray50,
    [ButtonVariant.outline]: Color.gray40,
    [ButtonVariant.outlineBlue]: Color.blue50,
    [ButtonVariant.danger]: Color.white,
    [ButtonVariant.outlineGray]: Color.gray50,
    [ButtonVariant.error]: Color.gray50,
    [ButtonVariant.neutral]: Color.gray30,
  },
  colorDisabled: {
    [ButtonVariant.primary]: Color.neutral40,
    [ButtonVariant.primaryPurple]: Color.neutral40,
    [ButtonVariant.secondary]: Color.neutral40,
    [ButtonVariant.light]: Color.neutral40,
    [ButtonVariant.outline]: Color.neutral40,
    [ButtonVariant.outlineBlue]: Color.neutral40,
    [ButtonVariant.danger]: Color.neutral40,
    [ButtonVariant.outlineGray]: Color.gray20,
    [ButtonVariant.error]: Color.gray20,
    [ButtonVariant.neutral]: Color.gray20,
  },
  border: {
    [ButtonVariant.primary]: "none",
    [ButtonVariant.primaryPurple]: "none",
    [ButtonVariant.secondary]: "none",
    [ButtonVariant.light]: "none",
    [ButtonVariant.outline]: `1px solid ${Color.neutral30}`,
    [ButtonVariant.outlineBlue]: `1px solid ${Color.blue50}`,
    [ButtonVariant.danger]: "none",
    [ButtonVariant.outlineGray]: `1px solid ${Color.sky50}`,
    [ButtonVariant.error]: `1px solid ${Color.red50}`,
    [ButtonVariant.neutral]: `1px solid ${Color.sky50}`,
  },
  borderHover: {
    [ButtonVariant.primary]: "none",
    [ButtonVariant.primaryPurple]: "none",
    [ButtonVariant.secondary]: "none",
    [ButtonVariant.light]: "none",
    [ButtonVariant.outline]: `1px solid ${Color.neutral50}`,
    [ButtonVariant.outlineBlue]: `1px solid ${Color.blue50}`,
    [ButtonVariant.danger]: "none",
    [ButtonVariant.outlineGray]: `1px solid ${Color.blue50}`,
    [ButtonVariant.error]: `1px solid ${Color.red40}`,
    [ButtonVariant.neutral]: `1px solid ${Color.sky50}`,
  },
  borderActive: {
    [ButtonVariant.primary]: "none",
    [ButtonVariant.primaryPurple]: "none",
    [ButtonVariant.secondary]: "none",
    [ButtonVariant.light]: "none",
    [ButtonVariant.outline]: `1px solid ${Color.neutral50}`,
    [ButtonVariant.outlineBlue]: `1px solid ${Color.blue50}`,
    [ButtonVariant.danger]: "none",
    [ButtonVariant.outlineGray]: `1px solid ${Color.blue50}`,
    [ButtonVariant.error]: `1px solid ${Color.red50}`,
    [ButtonVariant.neutral]: `1px solid ${Color.sky50}`,
  },
  borderDisabled: {
    [ButtonVariant.primary]: "none",
    [ButtonVariant.primaryPurple]: "none",
    [ButtonVariant.secondary]: "none",
    [ButtonVariant.light]: "none",
    [ButtonVariant.outline]: `1px solid ${Color.neutral10}`,
    [ButtonVariant.outlineBlue]: `1px solid ${Color.neutral10}`,
    [ButtonVariant.danger]: "none",
    [ButtonVariant.outlineGray]: `1px solid ${Color.sky50}`,
    [ButtonVariant.error]: `1px solid ${Color.sky50}`,
    [ButtonVariant.neutral]: `1px solid ${Color.sky50}`,
  },
};

const buttonPaddings = {
  [ButtonSize.sm]: "6px 10px",
  [ButtonSize.md]: "10px 12px",
  [ButtonSize.ml]: "12px 16px",
  [ButtonSize.lg]: "14px 32px",
};

type Props = {
  children: any;
  variant?: ButtonVariant;
  size?: ButtonSize;
  disabled?: boolean;
  active?: boolean;
  loading?: boolean;
  onClick?: (args) => void;
  // function for when capture phase is needed for click handler
  onClickCapture?: (args) => void;
  square?: boolean;
  href?: string;
  // custom styles (overrides variants)
  style?: Record<string, any>;
  openNewTab?: boolean;
  tooltipContentKey?: string;
};

export const Button = ({
  children,
  href,
  disabled,
  active,
  loading = false,
  variant = ButtonVariant.primary,
  size = ButtonSize.lg,
  style,
  onClickCapture,
  onClick,
  square = false,
  openNewTab,
  tooltipContentKey,
}: Props) => {
  const { getResource } = useResource();

  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  if (loading === true) disabled = true;

  const commonProps = {
    href,
    children,
    variant,
    disabled,
    active,
    // dollar sign added to prevent styled props from being passed to DOM node and causing error
    $loading: loading,
    size,
    onClickCapture,
    onClick,
    ...(openNewTab && { target: "_blank" }),
    style,
    // Style properties from variant
    border: variantStyles.border[variant],
    borderHover: variantStyles.borderHover[variant],
    borderActive: variantStyles.borderActive[variant],
    borderDisabled: variantStyles.borderDisabled[variant],
    backgroundColor: variantStyles.backgroundColor[variant],
    backgroundColorHover: variantStyles.backgroundColorHover[variant],
    backgroundColorDisabled: variantStyles.backgroundColorDisabled[variant],
    backgroundColorActive: variantStyles.backgroundColorActive[variant],
    color: style?.color || variantStyles.color[variant],
    colorActive: style?.color || variantStyles.colorActive[variant],
    colorDisabled: variantStyles.colorDisabled[variant],
    ...(tooltipContentKey && {
      "data-tooltip-id": tooltipContentKey,
      onMouseEnter: () => setShowTooltip(true),
      onMouseLeave: () => setShowTooltip(false),
    }),
  };

  if (square)
    return (
      <>
        <SquareButton {...commonProps} />
        {showTooltip && tooltipContentKey && (
          <Tooltip
            tooltipId={tooltipContentKey}
            content={getResource(tooltipContentKey)}
            isOpen
            zIndexPriority
          />
        )}
      </>
    );

  return (
    <>
      <StyledButton padding={buttonPaddings[size]} {...commonProps}>
        {children}
        {loading && (
          <StyledLoadingDotsContainer className="no-hide-loading-dots">
            <LoadingDots />
          </StyledLoadingDotsContainer>
        )}
      </StyledButton>
      {showTooltip && tooltipContentKey && (
        <Tooltip
          tooltipId={tooltipContentKey}
          content={getResource(tooltipContentKey)}
          isOpen
          zIndexPriority
        />
      )}
    </>
  );
};
const StyledLoadingDotsContainer = styled.div`
  position: absolute;
  background-color: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled((props) => (
  <StyledCommonButton as={props.href ? "a" : "button"} {...props} />
))<{
  padding: string;
}>`
  font-size: 14px;
  /* For when there is an icon next to the text */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;

  /* Styles from size */
  padding: ${({ padding }) => padding};
`;
