import React from "react";
import styled from "styled-components";

import { Modal, StyledModalCaption } from "components/_modals/Modal";
import { Button } from "components/_buttons/Button";
import { Text } from "components/Text";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { TextType } from "ts/TextType";

type Props = {
  isOpen: boolean;
  title: TextType;
  caption?: TextType;
  margin?: string;
  actionButtonLabel: TextType;
  onCancel(): void;
  onClickActionButton(): void;
  onClose?(): void;
};

export const ActionConfirmationModal = ({
  isOpen,
  title,
  caption,
  margin,
  actionButtonLabel,
  onCancel,
  onClickActionButton,
  onClose,
}: Props) => {
  const modalStyle = {
    content: {
      height: "fit-content",
      width: "530px",
      padding: "30px",
      margin: margin ?? "auto",
      overflow: "none",
      border: `1px solid ${Color.sky20}`,
      borderRadius: "5px",
      background: Color.white,
      boxShadow: "0px 3px 6px #00000029",
    },
  };

  return (
    <Modal isOpen={isOpen} handleRequestCloseFunc={onClose} styles={modalStyle}>
      <StyledModal>
        <StyledTitleContainer>
          <StyledTitle>{title}</StyledTitle>
        </StyledTitleContainer>
        {caption && (
          <StyledActionConfirmationModalCaption>{caption}</StyledActionConfirmationModalCaption>
        )}

        <StyledButtonsRow>
          <Button variant={ButtonVariant.outline} size={ButtonSize.md} onClick={onCancel}>
            <Text resource="button.cancel" />
          </Button>
          <Button
            variant={ButtonVariant.danger}
            size={ButtonSize.md}
            onClick={onClickActionButton}
            style={{ marginLeft: 8 }}
          >
            {actionButtonLabel}
          </Button>
        </StyledButtonsRow>
      </StyledModal>
    </Modal>
  );
};

const StyledTitleContainer = styled.div`
  display: flex;
  width: 440px;
  font-size: 1.3125rem;
  color: ${Color.gray50};
  font-weight: bold;
`;

const StyledTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100px;
`;

const StyledButtonsRow = styled.div`
  margin-top: 15px;
  text-align: right;
`;

const StyledActionConfirmationModalCaption = styled(StyledModalCaption)`
  margin: 5px 0 0 0;
`;
