import { DemographicFilterMethod } from "@explorance/mly-types";
import { DemographicFilterHeader, DemographicFilter } from "ts/filters/demographicFilter";
import { SharingUser } from "ts/sharing";
import { PILLS_LIMITS } from "assets/constants/sharingDraftLimits";

export const validateUsersShown = (selectedUsers, updatedUsers) => {
  if (selectedUsers.filter((u) => u.isShown).length <= 5) {
    return updatedUsers.map((user, index) => {
      if (index < PILLS_LIMITS.MIN) {
        return { ...user, isShown: true };
      } else {
        return { ...user, isShown: false };
      }
    });
  }
  return updatedUsers;
};

// functions for transforming BE data for FE use
export const getVisibleDemFiltersFromData = (
  demographicName: string,
  allDemFilters: DemographicFilter[]
): DemographicFilterHeader => {
  const foundDem = allDemFilters?.find((df) => df.name === demographicName);
  if (!foundDem) return;
  return { id: foundDem?.id, name: demographicName };
};

export const getVisibleCommentDemsFromData = (
  d: Record<any, any>,
  allDemFilters: DemographicFilter[]
): DemographicFilter => {
  const foundDem = allDemFilters?.find((df) => df.id === d.id);
  if (!foundDem) return;

  return {
    id: d.id,
    name: d.name,
    totalCount: foundDem?.totalCount,
    method: d.method,
    listValues: d.method === DemographicFilterMethod.List ? d.values : [],
    searchValue: d.method === DemographicFilterMethod.Search ? d.values : [],
  };
};

export const getSharedUsersFromApiData = (apiData: any): SharingUser[] => {
  return apiData.map((user) => ({
    ...user,
    id: user.userId,
  }));
};

// functions for transforming FE data for BE
export const getVisibleCommentDemsForFetch = (d: DemographicFilter) => ({
  id: d.id,
  method: d.method,
  name: d.name,
  values: d.method === DemographicFilterMethod.List ? d.listValues : d.searchValue,
});
