import React, { ReactNode } from "react";
import styled from "styled-components";

import { useAppSelector } from "store";
import { FileUploadStatus } from "@explorance/mly-types";
import { Color } from "ts/enums/color";
import { LoadingDots } from "components/LoadingDots";
import { DemographicHeaderCell } from "./DemographicHeaderCell";

type Props = {
  isDemographicSelectionDisabled: boolean;
};

export const DataPreviewTable = ({ isDemographicSelectionDisabled }: Props) => {
  const { analysisFileSummary, uploadStatus } = useAppSelector((state) => state.dataSource);

  if (!analysisFileSummary || uploadStatus === FileUploadStatus.Uploading) {
    return (
      <StyledLoadingDotsContainer>
        <LoadingDots />
      </StyledLoadingDotsContainer>
    );
  }

  return (
    <StyledDataPreviewTable>
      <thead>
        <StyledHeaderTr>
          {analysisFileSummary.demographics?.map((d) => (
            <DemographicHeaderCell
              key={d.id}
              demographic={d}
              isDemographicSelectionDisabled={isDemographicSelectionDisabled}
            />
          ))}
        </StyledHeaderTr>
      </thead>
      <tbody>
        {analysisFileSummary.comments?.map((comment, commentIndex) => (
          <tr key={commentIndex}>
            {analysisFileSummary.demographics?.map((d, demographicIndex) => (
              <td key={`${commentIndex}-${demographicIndex}`}>
                <StyledTableContentContainer>
                  {comment[d.name] as ReactNode}
                </StyledTableContentContainer>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledDataPreviewTable>
  );
};

const StyledDataPreviewTable = styled.table`
  text-align: left;
  width: auto;
  border-spacing: 0;
  cursor: default;
  border-collapse: separate;
  table-layout: fixed;
  font-size: 0.875em;
  overflow: scroll;

  td {
    border-bottom: 1px solid ${Color.blue20};
    margin: 0px;
    padding: 16px;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: 1365px) {
    th,
    td {
      max-width: 480px;
    }
  }

  @media (min-width: 1366px) {
    th,
    td {
      max-width: 600px;
    }
  }
`;

const StyledHeaderTr = styled.tr`
  position: sticky;
  position: -webkit-sticky;
  top: 0;
`;

const StyledTableContentContainer = styled.div`
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  color: ${Color.gray50};
`;

const StyledLoadingDotsContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
