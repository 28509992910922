import React, { useRef } from "react";
import { useAppSelector, useAppDispatch } from "store";
import styled from "styled-components";

import { useResource } from "hooks/useResource";

import {
  setInputActive,
  setUserInput,
  removeComment,
  clearAllComments,
  setExcludedCommentList,
} from "store/analysisSettings/settingsSlice";
import { Pill, PillSize } from "components/_pills/Pill";
import { Icon, IconType } from "components/_icons/Icon";
import { Button } from "components/_buttons/Button";
import { TextTruncator } from "components/TextTruncator";
import { Text } from "components/Text";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";

export const UserInputSection = () => {
  const state = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();

  const { getResource } = useResource();

  const inputRef = useRef(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && state.userInput.trim() !== "") {
      dispatch(
        setExcludedCommentList({
          comment: state.userInput.trim(),
          isUserInput: true,
        })
      );
      dispatch(setUserInput(""));
    }
  };

  const handleOnBlur = () => {
    dispatch(setInputActive(false));
    if (state.userInput.trim() !== "") {
      dispatch(
        setExcludedCommentList({
          comment: state.userInput.trim(),
          isUserInput: true,
        })
      );
    }
    dispatch(setUserInput(""));
  };

  const handleActiveInput = () => {
    dispatch(setInputActive(true));
    inputRef.current.focus();
  };

  return (
    <StyledParentContainer>
      <StyledHeaderContainer>
        <Text resource="analysisSettings.prefiltering.inputSection.header" />

        {state.excludedCommentList.length > 0 && (
          <Button
            size={ButtonSize.sm}
            variant={ButtonVariant.secondary}
            style={{
              gap: "6px",
              padding: "6px 12px",
            }}
            onClick={() => dispatch(clearAllComments())}
          >
            <Icon type={IconType.x} size={16} />
            <Text resource="analysisSettings.prefiltering.inputSection.removeComments" />
          </Button>
        )}
      </StyledHeaderContainer>
      <StyledUserInputFieldContainer isInputActive={state.inputActive} onClick={handleActiveInput}>
        <StyledInputPillContainer>
          {state.excludedCommentList.length > 0 &&
            state.excludedCommentList.map((excludedComment, index) => (
              <Pill
                round
                applyGridMargin
                backgroundColor={
                  excludedComment.selectAllExclusionCount ? Color.blue50 : Color.white
                }
                border={`1px solid ${Color.blue50}`}
                size={PillSize.xl}
                style={{
                  color: excludedComment.selectAllExclusionCount ? Color.white : Color.blue50,
                  maxWidth: "325px",
                  maxHeight: "42px",
                  marginTop: 0,
                }}
                iconColor={excludedComment.selectAllExclusionCount ? Color.white : Color.blue50}
                onDeletePill={() =>
                  dispatch(removeComment({ commentDetails: excludedComment, index }))
                }
                key={index}
              >
                <TextTruncator id={index} value={excludedComment.comment} />
              </Pill>
            ))}
          <StyledInputField
            onFocus={() => dispatch(setInputActive(true))}
            onBlur={handleOnBlur}
            onChange={(e) => dispatch(setUserInput(e.target.value))}
            onKeyDown={handleKeyDown}
            placeholder={getResource("analysisSettings.prefiltering.inputSection.placeholder")}
            value={state.userInput}
            ref={inputRef}
          />
        </StyledInputPillContainer>
      </StyledUserInputFieldContainer>
    </StyledParentContainer>
  );
};

const StyledParentContainer = styled.div`
  width: 50%;
`;

const StyledHeaderContainer = styled.div`
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const StyledUserInputFieldContainer = styled.div<{ isInputActive: boolean }>`
  height: 320px;
  border: ${({ isInputActive }) => `2px solid ${isInputActive ? Color.blue50 : Color.blue20}`};
  border-radius: 5px;
  padding: 11px;
  overflow-y: auto;
  cursor: text;
`;

const StyledInputPillContainer = styled.div`
  display: flex;
  align-content: flex start;
  flex-wrap: wrap;
`;

const StyledInputField = styled.input`
  border: none;
  outline: none;
  margin: 3px;
  padding: 8px 5px;
  flex-grow: 1;
  max-height: 40px;
`;
