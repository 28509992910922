import React from "react";
import styled from "styled-components";
import CreatableSelect from "react-select/creatable";

import { getCategoryStyles, getTagStyles } from "./helpers";
import { useResource } from "hooks/useResource";
import { useAppDispatch, useAppSelector } from "store";
import {
  CreatableSelectOption,
  setTags,
  setCategoryInput,
  setTagInput,
  setSelectedCategory,
} from "store/pin/pinSlice";

import { Button } from "components/_buttons/Button";
import { Popup } from "components/Popup";
import { Text } from "components/Text";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { PopupPosition } from "ts/enums/popupPosition";
import { Color } from "ts/enums/color";

type Props = {
  anchorEl: HTMLElement;
  bulkCommentCount?: number;
  setIsPopupOpen: (boolean) => void;
};

export const PinPopup = ({ anchorEl, setIsPopupOpen, bulkCommentCount }: Props) => {
  const state = useAppSelector((state) => state.commentPin);
  const dispatch = useAppDispatch();

  const { getResource } = useResource();

  return (
    <Popup
      isOpen
      anchorEl={anchorEl}
      placement={bulkCommentCount ? PopupPosition.bottomStart : PopupPosition.bottomEnd}
      popupStyles={{ width: 460, maxHeight: 400, padding: 10 }}
      fixedPopup
    >
      <StyledContentContainer>
        <StyledCommentContainer>
          <StyledHeader>
            {bulkCommentCount ? (
              <Text
                resource={{
                  key:
                    bulkCommentCount > 1
                      ? "comments.pinComment.popup.bulk.title"
                      : "comments.pinComment.popup.bulk.title.singular",
                  args: [`${bulkCommentCount}`],
                }}
              />
            ) : (
              <Text resource="comments.pinComment.popup.header" />
            )}
          </StyledHeader>
          <StyledComment>{!bulkCommentCount && state.comment.value}</StyledComment>
        </StyledCommentContainer>
        <div>
          <StyledLabel>
            <Text resource="comments.pinComment.popup.category" />
          </StyledLabel>
          <CreatableSelect
            styles={getCategoryStyles()}
            value={state.selectedCategory}
            onChange={(newValue) =>
              dispatch(setSelectedCategory(newValue as CreatableSelectOption))
            }
            inputValue={state.categoryInput}
            onInputChange={(newValue) => dispatch(setCategoryInput(newValue))}
            placeholder={getResource("comments.pinComment.popup.categorySelect.placeholder")}
            isSearchable
            isClearable
          />
        </div>
        <div>
          <StyledLabel>
            <Text resource="comments.pinComment.popup.tags" />
          </StyledLabel>
          <CreatableSelect
            styles={getTagStyles()}
            components={{ DropdownIndicator: null }}
            value={state.tags}
            onChange={(newValue) => dispatch(setTags(newValue as CreatableSelectOption[]))}
            inputValue={state.tagInput}
            onInputChange={(newValue) => dispatch(setTagInput(newValue))}
            placeholder={getResource("comments.pinComment.popup.input.placeholder")}
            isSearchable
            isMulti
          />
        </div>
        <StyledActionSection>
          <Button
            variant={ButtonVariant.light}
            size={ButtonSize.ml}
            onClick={() => setIsPopupOpen(false)}
          >
            <Text resource="button.cancel" />
          </Button>
          <Button
            variant={ButtonVariant.primary}
            size={ButtonSize.ml}
            disabled={!state.selectedCategory}
          >
            <Text resource="comments.pinComment.popup.button.pinComment" />
          </Button>
        </StyledActionSection>
      </StyledContentContainer>
    </Popup>
  );
};

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledHeader = styled.div`
  font-size: 12px;
  color: ${Color.gray30};
  font-weight: bold;
  margin-bottom: 5px;
`;

const StyledCommentContainer = styled.div`
  padding: 12px;
  background-color: ${Color.neutral10};
`;

const StyledComment = styled.div`
  color: ${Color.gray50};
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 50px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const StyledLabel = styled.div`
  font-size: 12px;
  color: ${Color.gray30};
  font-weight: bold;
  margin-bottom: 8px;
`;

const StyledActionSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 18px 0;
`;
