import React, { ReactNode } from "react";
import { Tooltip as ReactTooltip, PlacesType } from "react-tooltip";
import styled from "styled-components";
import { TextType } from "ts/TextType";

import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";

type Props = {
  tooltipId: string;
  content?: number | TextType;
  children?: ReactNode;
  className?: string;
  arrowColor?: Color;
  backgroundColor?: Color;
  delayHide?: number;
  place?: PlacesType;
  fontSize?: string;
  isOpen?: boolean;
  disable?: boolean;
  zIndexPriority?: boolean;
};

const getTooltipStyle = (
  fontSize: string,
  zIndexPriority: boolean,
  backgroundColor: Color
): React.CSSProperties => ({
  fontSize: fontSize ?? "12px",
  zIndex: zIndexPriority ? ZIndexStackingContext.high : ZIndexStackingContext.medium,
  backgroundColor: backgroundColor,
  position: "absolute",
  fontFamily: "Lato, sans-serif",
  transition: "opacity 100ms ease-in-out",
  visibility: "visible",
  maxWidth: "300px",
  overflowWrap: "break-word",
  overflow: "hidden",
  whiteSpace: "wrap",
  padding: "8px 21px",
  marginTop: "15px",
});

export const Tooltip = ({
  tooltipId,
  content,
  children,
  className,
  arrowColor,
  backgroundColor,
  delayHide,
  place,
  isOpen,
  fontSize,
  disable,
  zIndexPriority,
}: Props) => {
  return (
    <StyledTooltip
      id={tooltipId}
      className={className}
      place={place ?? "bottom"}
      arrowColor={arrowColor ?? "transparent"}
      delayShow={100}
      delayHide={delayHide ?? 50}
      style={getTooltipStyle(fontSize, zIndexPriority, backgroundColor)}
      aria-haspopup={true}
      hidden={disable}
      opacity={1}
      float
      {...(isOpen && { isOpen: isOpen })}
    >
      {content ?? children}
    </StyledTooltip>
  );
};

const StyledTooltip = styled(ReactTooltip)`
  ::before {
    background-color: transparent !important;
  }
`;
