import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "store";
import { routes } from "routes";

import { stripFileExtension } from "utils/stripFileExtension";
import { useResource } from "hooks/useResource";
import { createAnalysis, uploadFile } from "services/analysis";
import { patchUser } from "services/users";
import { setCurrentUser } from "store/auth/authSlice";
import { resetPageScroll } from "utils/resetPageScroll";
import { clearState, setAnalysisList, setIsWelcomeModalOpen } from "store/home/homePageSlice";
import { fetchRecentAnalyses } from "store/home/thunks";
import { setKeepSearchState } from "store/search/searchbarSlice";

import { Text } from "components/Text";
import { Searchbar } from "components/_inputs/Searchbar";
import { ProfileCard } from "components/ProfileCard";
import { FileDropCard } from "components/FileDropCard";
import { RecentUpdatesTable } from "./_layouts/RecentUpdatesTable";
import { QuickAnalysis } from "components/QuickAnalysis";
import { TrainingResources } from "./_layouts/TrainingResources";
import { ExploreAnalysesCard } from "components/ExploreAnalysesCard";
import { WelcomeModal } from "components/_modals/WelcomeModal";
import { DataSourceModal } from "common-layouts/DataSourceModal";
import { FeatureFlag } from "components/FeatureFlag";

import { Color } from "ts/enums/color";
import { RoleType, SocketEvent } from "@explorance/mly-types";
import { Feature } from "ts/enums/feature";
import { DataSourceModalType } from "ts/enums/dataSourceModalType";

export const HomePage = () => {
  const { searchTerm } = useAppSelector((state) => state.search);
  const { currentUser } = useAppSelector((state) => state.auth);
  const { importModalFileId } = useAppSelector((state) => state.dataSource);
  const { socket } = useAppSelector((state) => state.wsStream);
  const state = useAppSelector((state) => state.home);

  const dispatch = useAppDispatch();

  const { getResource } = useResource();
  const history = useHistory();

  const onSearchAnalysis = () => {
    const navigationParams = {
      pathname: routes.analysisListPage,
      search: `?search=${searchTerm}`,
      state: searchTerm,
    };
    dispatch(setKeepSearchState(true));
    history.push(navigationParams);
  };

  const completeOnboarding = async () => {
    await patchUser(currentUser.id, { skipWelcome: true })
      .then(() => {
        dispatch(setCurrentUser({ ...currentUser, skipWelcome: true }));
        dispatch(setIsWelcomeModalOpen(false));
      })
      .catch((err) => console.error(err));
  };

  const handleDropFile = async (file: File): Promise<string> => {
    try {
      const { data } = await createAnalysis(stripFileExtension(file.name));
      await dispatch(fetchRecentAnalyses(data.analysisId));
      await uploadFile(data.analysisId, file);
    } catch (e: any) {
      return e.response?.data?.details?.[0]?.rule === "string.max"
        ? "toast.fileUpload.fileNameTooLong"
        : "toast.fileUpload.error";
    }
  };

  useEffect(() => {
    dispatch(fetchRecentAnalyses());
    dispatch(setIsWelcomeModalOpen(!currentUser.skipWelcome));
    resetPageScroll();

    return () => {
      dispatch(clearState());
    };
  }, [dispatch, currentUser]);

  useEffect(() => {
    if (!socket) return;

    const handleRecentAnalysisUpdate = (recentAnalysis) => {
      if (recentAnalysis?.analysis) {
        dispatch(setAnalysisList(recentAnalysis.analysis));
      }
    };

    socket.on(SocketEvent.StreamRecentAnalysis, handleRecentAnalysisUpdate);
    socket.on(SocketEvent.ChangedAnalysis, handleRecentAnalysisUpdate);

    return () => {
      socket.off(SocketEvent.StreamRecentAnalysis, handleRecentAnalysisUpdate);
      socket.off(SocketEvent.ChangedAnalysis, handleRecentAnalysisUpdate);
    };
  }, [socket, dispatch]);

  return (
    <StyledHomePage
      className="fade-in"
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => e.preventDefault()}
    >
      <StyledLeftSection>
        <StyledHeaderSection className="fade-in-up">
          <h1>
            <Text resource="home.title" />
          </h1>
          <div>
            <Searchbar
              onSearch={onSearchAnalysis}
              placeholder={getResource("home.searchbar.placeholder")}
              customStyles={{ border: `1px solid ${Color.sky50}`, width: "auto" }}
            />
          </div>
        </StyledHeaderSection>
        <StyledCardContainer className="fade-in-up">
          <ProfileCard />
          {currentUser.roleType !== RoleType.Viewer ? (
            <StyledFileDropCardContainer>
              <FileDropCard onDropFile={handleDropFile} showHelpExtension />
            </StyledFileDropCardContainer>
          ) : (
            <ExploreAnalysesCard />
          )}
        </StyledCardContainer>
        <TrainingResources />
        <QuickAnalysis />
      </StyledLeftSection>
      <RecentUpdatesTable
        refetchTableData={(num: number) => dispatch(fetchRecentAnalyses(num))}
        analysisList={state.analysisList}
      />
      {!currentUser.skipWelcome && (
        <WelcomeModal isOpen={state.isWelcomeModalOpen} completeOnboarding={completeOnboarding} />
      )}
      <FeatureFlag feature={Feature.importProgressModal}>
        <DataSourceModal
          fileUploadStatus={
            state.analysisList.find((analysis) => analysis.fileId === importModalFileId)
              ?.fileUploadStatus
          }
          importSuccessCallback={() => {
            history.push(
              routes.settingsPage(
                state.analysisList.find((analysis) => analysis.fileId === importModalFileId)
                  .analysisId
              )
            );
          }}
          type={DataSourceModalType.ImportFile}
        />
      </FeatureFlag>
    </StyledHomePage>
  );
};

const StyledHomePage = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 24px;
`;

const StyledLeftSection = styled.div`
  flex-grow: 1;
  margin-right: 24px;
`;

const StyledCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (max-width: 1365px) {
    > div {
      width: 100%;
    }
  }
`;

const StyledFileDropCardContainer = styled.div`
  margin-top: 24px;
  height: 242px;

  @media (min-width: 960px) {
    width: 430px;
  }

  @media (min-width: 1366px) {
    width: 370px;
    margin-top: 0px;
  }
`;

const StyledHeaderSection = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 24px;

  h1 {
    font-size: 16px;
    margin-right: 24px;
    min-width: max-content;
  }
  div {
    flex-grow: 1;
  }
`;
