import React, { useContext, RefObject, forwardRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { routes } from "routes";
import { useResource } from "hooks/useResource";
import { setSelectedModelId } from "context/AnalysisContext/actions";
import { AnalysisContext } from "context/AnalysisContext";

import { addUsersToNewSharingDraft } from "services/analysis/sharing";
import { useAppDispatch, useAppSelector } from "store";
import { setStep } from "store/sharing/sharingSlice";
import {
  setPermissionFields,
  setShowCustomConditionError,
  setShowGroupNameError,
} from "store/sharing/permissionSlice";
import { getSharingMethodFromView } from "utils/getSharingMethodFromSharingView";
import { getUrlQueryString } from "utils/getUrlQueryString";

import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";

import { ButtonVariant } from "ts/enums/button";
import { ShareStep, SharingView } from "ts/enums/sharing";
import { Color } from "ts/enums/color";
import { saveSharingDraft } from "store/sharing/thunks";

type ShareToButtonProps = {
  handleGoBack: () => void;
  handleNavigateToPreview: () => void;
  onSave: (type: SharingView) => void;
  groupNameButtonRef: RefObject<HTMLInputElement>;
};

const ShareToButtons = forwardRef<HTMLInputElement, ShareToButtonProps>(function ShareToButtons(
  { handleGoBack, handleNavigateToPreview, onSave, groupNameButtonRef }: ShareToButtonProps,
  ref
) {
  const { isSavingSharing, view } = useAppSelector((state) => state.sharing);

  return (
    <StyledShareToButtonContainer ref={ref}>
      <Button variant={ButtonVariant.light} onClick={handleGoBack} disabled={isSavingSharing}>
        <Text resource="button.backToUserSelection" />
      </Button>
      <Button
        variant={ButtonVariant.outline}
        onClick={handleNavigateToPreview}
        disabled={isSavingSharing}
      >
        <Text resource="button.previewDashboard" />
      </Button>
      <div ref={groupNameButtonRef}>
        <Button onClick={() => onSave(view)} loading={isSavingSharing}>
          <Text
            resource={{
              key:
                view === SharingView.ShareToGroups ? "button.shareToGroup" : "button.shareAnalysis",
            }}
          />
        </Button>
      </div>
    </StyledShareToButtonContainer>
  );
});

// Main component
type Props = {
  onSave: (type: SharingView) => void;
  groupNameInputRef?: RefObject<HTMLInputElement>;
  groupNameButtonRef?: RefObject<HTMLInputElement>;
};

export const SharingActionsSection = ({ onSave, groupNameInputRef, groupNameButtonRef }: Props) => {
  const sharing = useAppSelector((state) => state.sharing);
  const permissionsState = useAppSelector((state) => state.permissions);
  const dispatch = useAppDispatch();
  const { selectedUsers } = useAppSelector((state) => state.sharingUserSelection);

  const [analysisState, contextDispatch] = useContext(AnalysisContext);
  const { getResource } = useResource();
  const history = useHistory();

  const handleGoToPermissionLevelSettings = async () => {
    try {
      await addUsersToNewSharingDraft({
        analysisId: analysisState.analysisDetails.id,
        sharingMethod: getSharingMethodFromView(sharing.view),
        userIds: selectedUsers.map((u) => u.id),
      });
      // Redirect to permission level settings by setting query params
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("step", ShareStep.PermissionSettings);
      queryParams.delete("search");
      history.push(`${window.location.pathname}?${queryParams.toString()}`);
      dispatch(setStep(ShareStep.PermissionSettings));
    } catch (error: any) {
      console.error(error.message);
    }
  };

  const handleNavigateToPreview = () => {
    // reset custom model id if not currently selected for preview page
    if (
      analysisState.analysisDetails.selectedModel?.customModelId &&
      analysisState.selectedModelId !== analysisState.analysisDetails.selectedModel.customModelId
    ) {
      contextDispatch(
        setSelectedModelId(analysisState.analysisDetails.selectedModel.customModelId)
      );
    }

    history.push(
      routes.overviewPage(
        analysisState.analysisDetails.id,
        getUrlQueryString({
          sharingPreview: sharing.view,
          step: sharing.step,
        })
      )
    );
  };

  const handleGoBack = () => {
    dispatch(setStep(ShareStep.UserSelection));
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("step", ShareStep.UserSelection);
    history.push(`${window.location.pathname}?${queryParams.toString()}`);
    if (permissionsState.showCustomConditionError || permissionsState.showGroupNameError) {
      dispatch(setShowCustomConditionError(false));
      dispatch(setShowGroupNameError(false));
      groupNameInputRef.current.value = getResource("sharing.groupName.default");
      dispatch(
        setPermissionFields({
          ...permissionsState.permissionFields,
          groupName: getResource("sharing.groupName.default"),
        })
      );
      dispatch(saveSharingDraft(analysisState.analysisDetails.id));
    }
  };

  return (
    <StyledSharingActionsSection>
      {sharing.step === ShareStep.UserSelection ? (
        <Button
          variant={ButtonVariant.outlineBlue}
          disabled={selectedUsers.length === 0}
          onClick={handleGoToPermissionLevelSettings}
        >
          <Text resource="button.goToPermissionLevelSettings" />
        </Button>
      ) : (
        <StyledShareToSection>
          {permissionsState.showCustomConditionError && (
            <StyledErrorContainer>
              <Icon type={IconType.info} color={Color.red50} size={12} />
              <span>
                <Text resource="sharing.permissionForm.customCondition.error" />
              </span>
            </StyledErrorContainer>
          )}
          {permissionsState.showGroupNameError && (
            <StyledErrorContainer>
              <Icon type={IconType.info} color={Color.red50} size={12} />
              <span>
                <Text resource="sharing.permission.groupName.emptyFieldError" />
              </span>
            </StyledErrorContainer>
          )}
          <ShareToButtons
            handleNavigateToPreview={handleNavigateToPreview}
            handleGoBack={handleGoBack}
            onSave={onSave}
            groupNameButtonRef={groupNameButtonRef}
          />
        </StyledShareToSection>
      )}
    </StyledSharingActionsSection>
  );
};

const StyledSharingActionsSection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 12px;
  padding: 24px 0;
  height: 100%;
`;

const StyledShareToButtonContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const StyledShareToSection = styled.div`
  width: max-content;
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

const StyledErrorContainer = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  gap: 6px;

  span {
    color: ${Color.red50};
    font-size: 15px;
  }
`;
