import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  SetStateAction,
  Dispatch,
} from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "routes";
import { flatten, cloneDeep, uniq } from "lodash";
import { ItemId, TreeData } from "@atlaskit/tree";

import { useResource } from "hooks/useResource";
import { recursiveFindAllChildTopicNodes, recursiveFindAllTopicNodes } from "utils/topics";
import { getModelStructure } from "services/models";
import { useQueryParams } from "hooks/useQueryParams";
import { mapDataToAnalysisModel } from "ts/customizedModel";
import { hashStringIntoNumber } from "utils/hashStringIntoNumber";
import { SaveModelValues } from "services/customModels";
import {
  mapApiTopicsTreeForDragNDropTree,
  recursiveFindAllTreeItemParentIds,
} from "./CustomTopicsTree/helpers";

import { CustomModelBuilderBox } from "./CustomModelBuilderBox";
import { EditPageBottomContainer } from "common-layouts/EditPageBottomContainer";
import { ToggleSwitch } from "components/_inputs/ToggleSwitch";
import { UnsavedChangesModal } from "components/_modals/UnsavedChangesModal";
import { ActionConfirmationModal } from "components/_modals/ActionConfirmationModal";
import { Text } from "components/Text";

import { CustomModelBuilderMode } from "ts/enums/customModelBuilderMode";
import { TopicFilter } from "ts/filters/topicFilter";
import { ApiTopicNode } from "ts/topic";
import { AnalysisModel } from "ts/filters/analysisModel";
import { Color } from "ts/enums/color";

type InitialCustomModel = {
  name: string;
  modelId: number;
  topics?: TopicFilter[];
};

type Props = {
  initialValues: InitialCustomModel;
  onSave: (values: SaveModelValues, skipCallback?: boolean) => Promise<boolean>;
};

export const CustomModelBuilder = ({ initialValues, onSave }: Props) => {
  // LOCAL STATES
  const [nameInputValue, setNameInputValue] = useState<string>(initialValues.name || "");
  const [showZeroTopicsError, setShowZeroTopicsError] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [showEmptyCustomModelNameError, setShowEmptyCustomModelNameError] =
    useState<boolean>(false);
  const [mappedTopicsCount, setMappedTopicsCount] = useState<number>(0);
  const [showSavePromptModal, setShowSavePromptModal] = useState<boolean>(false);
  const [unblockFunc, setUnblockFunc] = useState<any>();
  const [blockRouteChange, setBlockRouteChange] = useState<boolean>(false);
  const [hasBeenEdited, setHasBeenEdited] = useState<boolean>(false);
  const [showDiscardConfirmationModal, setShowDiscardConfirmationModal] = useState<boolean>(false);

  // CONTEXT STATES
  // Tree data
  const [initialTree, setInitialTree] = useState<TreeData>(mapApiTopicsTreeForDragNDropTree([]));
  const [tree, setTree] = useState<TreeData>(initialTree);
  // List of already selected topics for map topics feature
  // ONLY update these states with the full tree (including parents, not just edges)
  const [initialAllSelectedTopics, setInitialAllSelectedTopics] = useState<ApiTopicNode[]>([]);
  const [allSelectedTopics, setAllSelectedTopics] = useState<ApiTopicNode[]>([]);
  // Data of the selected model
  const [baseModel, setBaseModel] = useState<AnalysisModel>(null);
  const [updatedBaseModel, setUpdatedBaseModel] = useState<AnalysisModel>(null);
  // Misc
  const [mode, setMode] = useState<CustomModelBuilderMode>(CustomModelBuilderMode.view);
  const [showEmptyTopicNameError, setShowEmptyTopicNameError] = useState<boolean>(false);
  const [focusedId, setFocusedId] = useState<ItemId>(null);

  const { modelId, isTemplate } = useQueryParams();
  const history = useHistory();
  const { getResource } = useResource();
  const location = useLocation();

  const handleNameInputValueChange = (e) => {
    setHasBeenEdited(true);
    setBlockRouteChange(true);
    setNameInputValue(e.target.value);
    if (showEmptyCustomModelNameError) setShowEmptyCustomModelNameError(e.target.value === 0);
  };

  const handleSave = async (skipCallback = false) => {
    // Validate custom model name
    if (nameInputValue.trim().length === 0) {
      setShowErrors(true);
      setShowEmptyCustomModelNameError(true);
    }

    // Validate topic names
    const emptyTopics = Object.keys(tree.items)
      .map((k) => tree.items[k])
      .filter((item) => item.data.title?.trim().length === 0);

    const hasEmptyTitleErrors = emptyTopics.length > 0;
    if (hasEmptyTitleErrors) {
      setShowErrors(true);

      // Expand all parents of empty topics
      const parentTopicIdsToExpand = uniq(
        emptyTopics.flatMap((topic) => recursiveFindAllTreeItemParentIds(topic, tree))
      );
      const newTree = cloneDeep(tree);
      parentTopicIdsToExpand.forEach((id) => {
        newTree.items[id].isExpanded = true;
      });
      setTree(newTree);
    }
    setShowEmptyTopicNameError(hasEmptyTitleErrors);

    // Validate having at least created one topic
    const hasZeroTopics = Object.keys(tree.items).length === 1;
    setShowZeroTopicsError(hasZeroTopics);
    if (hasZeroTopics) {
      setShowErrors(true);
    }

    // Exit if anything is invalid
    if (nameInputValue.trim().length === 0 || hasEmptyTitleErrors || hasZeroTopics) {
      return { isValid: false };
    }

    // Prepare request body for api call
    const treeItems = Object.keys(tree.items).map((k) => tree.items[k]);

    // Reorder items in orthe body payload to reflect how they were reordered in the UI
    const allIdsOrderedByChildren = treeItems.map((ti) => ti.children).flat();
    const reorderedTreeItems = uniq(
      allIdsOrderedByChildren.map((id) => treeItems.find((item) => item.id === id))
    );

    const requestBodyTopics = reorderedTreeItems
      .map((item: any) => {
        const id = hashStringIntoNumber(item.id);
        const parent = reorderedTreeItems.find((i: any) => i.id === item.parentId);
        return {
          id,
          parentId:
            parent?.data?.title === "root" || !parent ? -2 : hashStringIntoNumber(item.parentId),
          name: item.data.title,
          mappings: item.data.mappedTopics || [],
          parentName: parent?.data?.title || null,
        };
      })
      .filter((topic) => topic.parentId); // filter out the root

    const requestBody = {
      name: nameInputValue,
      graphId: Number(modelId),
      topics: requestBodyTopics,
    };

    // Send request body to page level component to perform api call
    setBlockRouteChange(false);
    if (await onSave(requestBody as SaveModelValues, skipCallback)) {
      setInitialTree(tree);
    }

    return { isValid: true };
  };

  const onClickDiscardChangesModalButton = () => {
    unblockFunc();
    setShowSavePromptModal(false);
  };

  const onClickSaveChangesModalButton = async () => {
    const { isValid } = await handleSave(true);
    setShowSavePromptModal(false);
    if (isValid) {
      unblockFunc();
    }
  };

  const discardChanges = () => {
    setHasBeenEdited(false);
    setNameInputValue(initialValues.name || "");
    setTree(initialTree);
    setAllSelectedTopics(initialAllSelectedTopics);
    setUpdatedBaseModel({ ...updatedBaseModel, topics: cloneDeep(baseModel.topics) });
    setShowDiscardConfirmationModal(false);
    setShowErrors(false);
  };

  const switchMode = () => {
    setMode((prev) =>
      prev === CustomModelBuilderMode.view
        ? CustomModelBuilderMode.edit
        : CustomModelBuilderMode.view
    );
  };

  // set initial states given the model structure api data
  useEffect(() => {
    getModelStructure(Number(modelId) || initialValues.modelId).then(({ data }) => {
      setBaseModel(mapDataToAnalysisModel(data));
      setUpdatedBaseModel(mapDataToAnalysisModel(data));
      if (isTemplate) {
        setInitialTree(mapApiTopicsTreeForDragNDropTree(data, getResource));
        setTree(mapApiTopicsTreeForDragNDropTree(data, getResource));
      } else if (initialValues.topics) {
        setInitialTree(mapApiTopicsTreeForDragNDropTree(initialValues.topics));
        setTree(mapApiTopicsTreeForDragNDropTree(initialValues.topics));
      }
    });
  }, [modelId, isTemplate, getResource, initialValues.topics, initialValues.modelId]);

  // if the custom model is using the base model as template, set the selected topic states equal to all topics in the base model
  useEffect(() => {
    if (isTemplate === "true" && baseModel) {
      const allTopics = [
        ...baseModel.topics.flatMap((t) => recursiveFindAllTopicNodes(t)),
        ...baseModel.topics,
      ];
      setInitialAllSelectedTopics(allTopics);
      setAllSelectedTopics(allTopics);

      return;
    }
  }, [baseModel, isTemplate]);

  // if the custom model already exists, set the selected topic states equal to the selected topics
  useEffect(() => {
    if (
      location.pathname
        .split("/")
        .filter((p) => p.trim() !== "")
        .some((p) => p === "edit") &&
      !hasBeenEdited
    ) {
      const existingMappings = [];
      Object.keys(tree.items).forEach((k) => {
        if (tree.items[k].data.mappedTopics) {
          tree.items[k].data.mappedTopics.forEach((mt) => {
            existingMappings.push(mt);
          });
        }
      });
      setInitialAllSelectedTopics(existingMappings);
      setAllSelectedTopics(existingMappings);
    }
  }, [tree.items, location.search, history, location.pathname, hasBeenEdited]);

  // Re-validate topic to remove error message if they are resolved
  useEffect(() => {
    if (
      showEmptyTopicNameError &&
      !Object.keys(tree.items)
        .map((k) => tree.items[k])
        .some((item) => item.data.title?.trim().length === 0)
    ) {
      setShowEmptyTopicNameError(false);
    }
    if (Object.keys(tree.items).length > 1) setShowZeroTopicsError(false);
  }, [tree, showEmptyTopicNameError]);

  // Handle route change blocker for unsaved changes
  useEffect(() => {
    if (blockRouteChange) {
      const unblock = history.block((destination) => {
        setShowSavePromptModal(true);
        setUnblockFunc(() => () => {
          unblock();
          history.replace(destination);
        });
        return false;
      });

      return () => unblock();
    }
  }, [blockRouteChange, history]);

  const updateMappedTopicsCount = (tree: TreeData) => {
    const allTopics = Object.keys(tree.items).map((key) => tree.items[key]);
    const allMappedTopics = flatten(
      allTopics.map((topic) => topic.data.mappedTopics).filter(Boolean)
    );
    setMappedTopicsCount(allMappedTopics.length);
  };

  useEffect(() => {
    updateMappedTopicsCount(tree);
  }, [tree]);

  if (!baseModel || !updatedBaseModel) return null;

  return (
    <CustomModelBuilderContext.Provider
      value={{
        tree,
        setTree,
        initialTree,
        setInitialTree,
        mode,
        switchMode,
        showEmptyTopicNameError,
        setShowEmptyTopicNameError,
        initialAllSelectedTopics,
        setInitialAllSelectedTopics,
        baseModel,
        setBaseModel,
        updatedBaseModel,
        setUpdatedBaseModel,
        allSelectedTopics,
        setAllSelectedTopics,
        focusedId,
        setFocusedId,
      }}
    >
      <StyledCustomModelBuilderHeaderContainer className="fade-in">
        <StyledCustomModelBuilderHeader hasError={showEmptyCustomModelNameError}>
          <span>
            <Text resource="customModels.topicName.label" />
          </span>
          <input maxLength={100} value={nameInputValue} onChange={handleNameInputValueChange} />
          <div>
            <Text
              resource={{
                key: "customModels.topicsList.title",
                args: [
                  `${mappedTopicsCount}`,
                  `${baseModel?.topics.flatMap((t) => recursiveFindAllChildTopicNodes(t)).length}`,
                ],
              }}
            />
          </div>
        </StyledCustomModelBuilderHeader>
        <ToggleSwitch
          labelKey="customModels.editTopicStructure.label"
          onChange={switchMode}
          checked={mode === CustomModelBuilderMode.edit}
        />
      </StyledCustomModelBuilderHeaderContainer>
      <CustomModelBuilderBox
        updateMappedTopicsCount={updateMappedTopicsCount}
        onTreeChange={() => {
          setHasBeenEdited(true);
          setBlockRouteChange(true);
        }}
      />

      <EditPageBottomContainer
        backToListButtonLabel={<Text resource="button.backToModelList" />}
        showErrors={showErrors}
        errorInfo={[
          {
            hasError: showEmptyCustomModelNameError,
            errorLabel: <Text resource="customModels.emptyModelName.message" />,
          },
          {
            hasError: showEmptyTopicNameError,
            errorLabel: <Text resource="customModels.emptyTopicName.message" />,
          },
          {
            hasError: showZeroTopicsError,
            errorLabel: <Text resource="customModels.zeroTopics.message" />,
          },
        ]}
        pageHasBeenEdited={hasBeenEdited}
        discardButtonLabel={<Text resource="button.discardChanges" />}
        saveButtonLabel={<Text resource="button.saveChanges" />}
        onClickBackToButton={() => history.push(routes.customizeCustomModelsPage)}
        onClickDiscard={() => setShowDiscardConfirmationModal(true)}
        onClickSave={() => handleSave()}
      />

      {showSavePromptModal && (
        <UnsavedChangesModal
          captionTitle={
            <Text resource={{ key: "modal.unsavedChanges.title", args: [nameInputValue] }} />
          }
          onSave={onClickSaveChangesModalButton}
          onDiscard={onClickDiscardChangesModalButton}
          onClose={() => setShowSavePromptModal(false)}
        />
      )}
      <ActionConfirmationModal
        isOpen={showDiscardConfirmationModal}
        title={<Text resource={{ key: "modal.discardChanges.title", args: [nameInputValue] }} />}
        actionButtonLabel={<Text resource="button.discard" />}
        onCancel={() => setShowDiscardConfirmationModal(false)}
        onClickActionButton={discardChanges}
      />
    </CustomModelBuilderContext.Provider>
  );
};

type CustomModelBuilderContext = {
  baseModel: AnalysisModel;
  setBaseModel: Dispatch<SetStateAction<AnalysisModel>>;
  updatedBaseModel: AnalysisModel;
  setUpdatedBaseModel: Dispatch<SetStateAction<AnalysisModel>>;
  initialTree: TreeData;
  setInitialTree: Dispatch<SetStateAction<TreeData>>;
  tree: TreeData;
  setTree: Dispatch<SetStateAction<TreeData>>;
  initialAllSelectedTopics: ApiTopicNode[];
  setInitialAllSelectedTopics: Dispatch<SetStateAction<ApiTopicNode[]>>;
  allSelectedTopics: ApiTopicNode[];
  setAllSelectedTopics: Dispatch<SetStateAction<ApiTopicNode[]>>;
  mode: CustomModelBuilderMode;
  switchMode: Dispatch<SetStateAction<CustomModelBuilderMode>>;
  focusedId: ItemId;
  setFocusedId: Dispatch<SetStateAction<ItemId>>;
  showEmptyTopicNameError: boolean;
  setShowEmptyTopicNameError: Dispatch<SetStateAction<boolean>>;
};

const CustomModelBuilderContext = createContext({} as CustomModelBuilderContext);
export const useCustomModelBuilder = () => useContext(CustomModelBuilderContext);

const StyledCustomModelBuilderHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledCustomModelBuilderHeader = styled.div<{ hasError: boolean }>`
  display: flex;
  align-items: center;
  color: ${Color.gray40};
  font-size: 13px;
  padding: 16px 0;

  > input {
    padding: 10px;
    border: 1px solid ${({ hasError }) => (hasError ? Color.red30 : Color.blue20)};
    border-radius: 2px;
    margin: 0 16px;
    color: ${Color.gray40};
    width: 375px;
  }
  > div {
    color: ${Color.gray50};
    font-size: 14x;
  }
`;
