import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Comment } from "ts/comments";

export type CreatableSelectOption = {
  label: string;
  value: string;
};

type PinState = {
  comment: Comment;
  selectedCategory: CreatableSelectOption;
  categoryInput: string;
  tags: CreatableSelectOption[];
  tagInput: string;
};

const initialState: PinState = {
  comment: null,
  selectedCategory: null,
  categoryInput: "",
  tags: [],
  tagInput: "",
};

const TAG_LIMIT = 100;

const PinSlice = createSlice({
  name: "pinSlice",
  initialState,
  reducers: {
    setComment: (state, action: PayloadAction<Comment>) => {
      state.comment = action.payload;
    },
    setCategoryInput: (state, action: PayloadAction<string>) => {
      state.categoryInput = action.payload;
    },

    setSelectedCategory: (state, action: PayloadAction<CreatableSelectOption>) => {
      if (action.payload?.label.trim().length > 0 || !action.payload)
        state.selectedCategory = action.payload;
    },
    setTags: (state, action: PayloadAction<CreatableSelectOption[]>) => {
      if (
        (action.payload[action.payload.length - 1]?.label.trim().length > 0 &&
          state.tags.length <= TAG_LIMIT) ||
        action.payload.length === 0
      ) {
        state.tags = action.payload;
      }
    },
    setTagInput: (state, action: PayloadAction<string>) => {
      if (action.payload.trim().length <= TAG_LIMIT) state.tagInput = action.payload;
    },
    clearState: () => initialState,
  },
});

export const {
  setComment,
  setCategoryInput,
  setTagInput,

  setSelectedCategory,
  setTags,
  clearState,
} = PinSlice.actions;

export default PinSlice.reducer;
